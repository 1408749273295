import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/drawer";
import {
  getPrivilegeTypes,
  updatePrivilegeAssignedType,
} from "src/store/privilege/saga";
import { PrivilegeDto, PrivilegeTypeDto } from "src/store/privilege/type";
import * as Yup from "yup";
const TypeEditModal = ({
  onHide,
  open,
  onSuccessClick,
  privilege,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
  privilege: PrivilegeDto;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [types, setTypes] = useState<PrivilegeTypeDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getPrivilegeTypes({
          payload: {
            onSuccess: (msg, payload) => setTypes(payload),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <CustomModal open={open} onHide={onHide} title={"Hizmet Tipi Düzenle"}>
      <CustomFormik
        onHide={onHide}
        loader
        initialValues={{
          PrivilegeAssignedTypes: privilege.PrivilegeAssignedTypes
            ? privilege.PrivilegeAssignedTypes.map(x => {
                return x.PrivilegeTypeId;
              })
            : [],
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={values => {
          dispatch(
            updatePrivilegeAssignedType({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values.PrivilegeAssignedTypes,
              id: privilege.Id,
            })
          );
        }}
        inputs={[
          {
            field: "PrivilegeAssignedTypes",
            inputType: InputType.checkpicker,
            label: t("Tipler"),
            lookup: {
              data: types,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: "Tip Seçiniz",
            },
          },
        ]}
      />
    </CustomModal>
  );
};

export default TypeEditModal;
