import { IconName } from "@ailibs/feather-react-ts";
import { PermissionConsts } from "src/permissions/permissionConsts";

export const MenuList: MenuListProp[] = [
  {
    title: "Dashboard",
    icon: "home",
    link: "/dashboard",
  },
  {
    title: "Slider",
    icon: "sliders",
    link: "/sliders",
    permission: [PermissionConsts.Sliders],
  },

  {
    title: "Suppliers",
    icon: "users",
    link: "/#",
    isNotAdmin: true,
    permission: [PermissionConsts.SuppliersMain],
    submenu: [
      {
        title: "Suppliers",
        link: "/suppliers",
        isNotAdmin: true,
        permission: [PermissionConsts.Suppliers],
      },
      {
        title: "Supplier Types",
        link: "/suppliertypes",
        permission: [PermissionConsts.SupplierTypes],
      },
    ],
  },
  {
    title: "Campaigns",
    icon: "award",
    link: "/campaigns",
    isNotAdmin: true,
    permission: [PermissionConsts.Campaigns],
  },
  {
    title: "Privileges",
    icon: "star",
    link: "/#",
    permission: [PermissionConsts.PrivilegesMain],
    submenu: [
      {
        title: "Privileges",
        link: "/privileges",
        permission: [PermissionConsts.Privileges],
      },

      {
        title: "Uninvoiced Privileges",
        link: "/uninvoiced-privileges",
        permission: [PermissionConsts.NotinvoicePackages],
      },
      {
        title: "Invoiced Privileges",
        link: "/invoiced-privileges",
        permission: [PermissionConsts.InvoicePackages],
      },
    ],
  },
  {
    title: "Privilege Categories",
    icon: "bookmark",
    link: "/privilegecategories",
    permission: [PermissionConsts.Categories],
  },
  {
    title: "Lastik",
    icon: "aperture",
    link: "/#",
    permission: [PermissionConsts.TiresMain],
    submenu: [
      {
        title: "Lastik Satışları",
        link: "/tire-sales",
        permission: [PermissionConsts.TireSales],
      },
      {
        title: "Lastik Yönetimi",
        link: "/#",
        permission: [PermissionConsts.TireSubMain],
        submenu: [
          {
            title: "Lastikler",
            link: "/tires",
            isNotAdmin: true,
            permission: [PermissionConsts.Tires],
          },
          {
            title: "Tedarikçiler",
            link: "/tire-suppliers",
            isNotAdmin: true,
            permission: [PermissionConsts.TireSuppliers],
          },
          {
            title: "Lastikçiler",
            link: "/tire-services",
            isNotAdmin: true,
            permission: [PermissionConsts.TireServices],
          },
          {
            title: "Tanımlamalar",
            link: "/#",
            permission: [PermissionConsts.TireDefinitions],
            submenu: [
              {
                title: "Marka",
                link: "/tire-brands",
              },

              {
                title: "Taban Genişliği",
                link: "/tire-treadwidths",
              },

              {
                title: "Yanak Genişliği",
                link: "/tire-sidewallwidths",
              },
              {
                title: "Çap",
                link: "/tire-diameters",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Ürün - Sipariş",
    icon: "shopping-bag",
    link: "/#",
    isNotAdmin: true,
    permission: [PermissionConsts.ProductMain],
    submenu: [
      {
        title: "Siparişler",
        link: "/orders",
        isNotAdmin: true,
        permission: [PermissionConsts.ProductSales],
      },
      {
        title: "Ürünler",
        link: "/products",
        permission: [PermissionConsts.Products],
      },
      {
        title: "Banner",
        link: "/product-banners",
        permission: [PermissionConsts.ProductBanners],
      },
      {
        title: "Tanımlamalar",
        link: "/#",
        permission: [PermissionConsts.ProductDefinitions],
        submenu: [
          {
            title: "Ürün Kategorileri",
            link: "/product-categories",
          },

          {
            title: "İptal Sebepleri",
            link: "/order-cancellation-reasons",
          },
          {
            title: "İade Sebepleri",
            link: "/order-refund-reasons",
          },
          {
            title: "Kargo Firmaları",
            link: "/order-shipping-companies",
          },
        ],
      },
    ],
  },

  {
    title: "Coupons",
    icon: "credit-card",
    link: "/#",
    permission: [PermissionConsts.Coupons],
    submenu: [
      {
        title: "Coupons",
        link: "/coupons",
        isNotAdmin: true,
        permission: [PermissionConsts.Coupons],
      },
      {
        title: "Kupon Grupları",
        link: "/coupon-groups",
        permission: [PermissionConsts.Coupons],
      },
    ],
  },
  {
    title: "Packages",
    icon: "box",
    link: "/#",
    isNotAdmin: true,
    permission: [PermissionConsts.PackageMain],
    submenu: [
      {
        title: "Packages",
        link: "/packages",
        isNotAdmin: true,
        permission: [PermissionConsts.Packages],
      },
      {
        title: "Uninvoiced Packages",
        link: "/uninvoiced-packages",
        permission: [PermissionConsts.NotinvoicePackages],
      },
      {
        title: "Invoiced Packages",
        link: "/invoiced-packages",
        permission: [PermissionConsts.InvoicePackages],
      },

      {
        title: "Faturalanmamış Üye İptal Ödemeleri",
        link: "/uninvoiced-cancellation",
        permission: [PermissionConsts.NotinvoicePackages],
      },
      {
        title: "Faturalanmış Üye İptal Ödemeleri",
        link: "/invoiced-cancellation",
        permission: [PermissionConsts.InvoicePackages],
      },
    ],
  },
  {
    title: "Kurumsal Müşteriler",
    icon: "users",
    link: "/#",
    submenu: [
      {
        title: "Müşteriler",
        link: "/corporate-clients",
      },
    ],
  },

  {
    title: "Customers",
    icon: "users",
    link: "/#",
    isNotAdmin: true,
    permission: [PermissionConsts.CustomersMain],
    submenu: [
      {
        title: "Customers",
        link: "/customers",
        isNotAdmin: true,
        permission: [PermissionConsts.Customers],
      },
      {
        title: "Paket Alan Üyeler",
        link: "/purchased-customers",
        isNotAdmin: true,
        permission: [PermissionConsts.PurchasedCustomers],
      },
      {
        title: "MembershipCancelRequests",
        link: "/membership-cancel-requests",
        isNotAdmin: true,
        permission: [PermissionConsts.CancelRequest],
      },
      {
        title: "İptal Eden Üyeler",
        link: "/cancelled-customers",
        isNotAdmin: true,
        permission: [PermissionConsts.CancelCustomer],
      },
      {
        title: "Üye Aramaları",
        link: "/customer-calllogs",
        isNotAdmin: true,
        permission: [PermissionConsts.CustomerCallLog],
      },
      {
        title: "Tanımlamalar",
        link: "/#",
        permission: [PermissionConsts.CustomerDefinitions],
        submenu: [
          {
            title: "MembershipCancelTypes",
            link: "/membership-cancel-types",
          },
          {
            title: "Üye Arama Konuları",
            link: "/customer-calllog-topics",
          },
          {
            title: "İletişim Kanalı",
            link: "/calllog-channel",
          },
          {
            title: "Gruplar",
            link: "/customer-groups",
          },
          {
            title: "Job",
            link: "/customer-job",
          },
          {
            title: "Education Status",
            link: "/customer-education-status",
          },
        ],
      },
    ],
  },
  {
    title: "Bakımlar",
    icon: "tool",
    link: "/#",
    isNotAdmin: true,
    permission: [PermissionConsts.Maintenances],
    submenu: [
      {
        title: "Bakım Taslakları",
        link: "/maintenance-drafts",
        permission: [PermissionConsts.Maintenances],
      },
      {
        title: "Tanımlamalar",
        link: "/#",
        permission: [PermissionConsts.MaintenanceDefinitions],
        submenu: [
          {
            title: "Markalar",
            link: "/vehicle-brands",
            permission: [PermissionConsts.MaintenanceDefinitions],
          },
          {
            title: "Modeller",
            link: "/vehicle-models",
            permission: [PermissionConsts.MaintenanceDefinitions],
          },
          {
            title: "Bakım Kategorileri",
            link: "/maintenance-categories",
            permission: [PermissionConsts.MaintenanceDefinitions],
          },
          {
            title: "Bakım Kartları",
            link: "/service-maintenance-cards",
            permission: [PermissionConsts.MaintenanceDefinitions],
          },
        ],
      },
    ],
  },

  {
    title: "Finans",
    icon: "file",
    link: "/#",
    permission: [PermissionConsts.FinanceMain],
    submenu: [
      {
        title: "Üye Raporu",
        link: "/reports/customer/chart",
      },
      {
        title: "Üyeler",
        link: "/reports/customer",
      },

      {
        title: "Tahsilat Hareketleri",
        link: "/transaction-reports",
      },
    ],
  },
  {
    title: "Raporlar",
    icon: "file",
    link: "/#",
    isNotAdmin: true,
    permission: [PermissionConsts.ReportMain],
    submenu: [
      {
        title: "Link Gösterim",
        link: "/link-clicks/all",
      },
      {
        title: "Ispark Raporu",
        link: "/ispark-reports",
        permission: [PermissionConsts.ReportMain],
      },
      {
        title: "Hizmet Puanlamaları",
        link: "/customer-ratings",
        isNotAdmin: true,
        permission: [PermissionConsts.PrivilegePoint],
      },
      {
        title: "Kara Liste",
        link: "/blacklists",
        permission: [PermissionConsts.Blacklists],
      },
      {
        title: "Sms Gönderim Logları",
        link: "/sms-logs",
        permission: [PermissionConsts.SmsLogs],
      },
      {
        title: "E-posta Gönderim Logları",
        link: "/email-logs",
        permission: [PermissionConsts.EmailLogs],
      },
    ],
  },

  {
    title: "Web Site Yönetimi",
    icon: "globe",
    link: "/#",
    permission: [PermissionConsts.WebsiteMain],
    submenu: [
      {
        title: "Slider",
        link: "/web-sliders",
      },
      {
        title: "Hakkımızda",
        link: "/about-us",
      },
      {
        title: "İletişim Talepleri",
        link: "/web-contacts",
      },
      {
        title: "İletişim Talep Kategorileri",
        link: "/web-contacts-categories",
      },
      {
        title: "Ayarlar",
        link: "/web-settings",
      },
      {
        title: "Gizlilik",
        link: "/privacy",
      },
      {
        title: "Hesap Silme İstemi",
        link: "/account-deletion-prompt",
      },
    ],
  },
  {
    title: "Faqs",
    icon: "lock",
    link: "/#",
    permission: [PermissionConsts.FaqsMain],
    submenu: [
      {
        title: "Faqs",
        link: "/faqs",
      },
      {
        title: "FaqsCategori",
        link: "/faqs/categories",
      },
    ],
  },
  {
    title: "DatabankMenu",
    icon: "book",
    link: "/#",
    isNotAdmin: true,
    permission: [PermissionConsts.Databank],
    submenu: [
      {
        title: "DatabankMenu",
        link: "/databanks",
        isNotAdmin: true,
      },
      {
        title: "DatabankCategoryMenu",
        link: "/databanks-categories",
      },
    ],
  },
  {
    title: "Support Tickets",
    icon: "message-circle",
    link: "/#",
    isNotAdmin: true,
    permission: [PermissionConsts.TicketsMain],
    submenu: [
      {
        title: "Talepler",
        link: "/ticket/all",
        isNotAdmin: true,
        permission: [PermissionConsts.Tickets],
      },
      {
        title: "Talep Konuları",
        link: "/ticket-subjects",
        permission: [PermissionConsts.TicketSubjects],
      },
      {
        title: "Talep İletişim Kanalları",
        link: "/ticket-channels",
        permission: [PermissionConsts.TicketChannels],
      },
    ],
  },
  {
    title: "Bildirimler",
    icon: "bell",
    link: "/#",
    permission: [PermissionConsts.Notifications],
    submenu: [
      {
        title: "Planlanan Bildirimler",
        link: "/planned-notifications",
      },
      {
        title: "Bildirim Aksiyonları",
        link: "/notification-actions",
      },
    ],
  },
  {
    title: "Tanımlamalar",
    icon: "file-text",
    link: "/#",
    permission: [PermissionConsts.DefinitionMain],
    submenu: [
      {
        title: "Uyarı Mesajları",
        link: "/#",
        submenu: [
          {
            title: "Backend",
            link: "/backend-localizations",
          },
          {
            title: "Frontend",
            link: "/frontend-localizations",
          },
        ],
      },
      {
        title: "Bildirim Mesajları",
        link: "/notification-messages",
      },
      {
        title: "Vehicle Definitions",
        link: "/#",
        submenu: [
          {
            title: "Vehicle Change Status",
            link: "/vehicle-change-statuses",
          },
          {
            title: "Vehicle Color",
            link: "/vehicle-color",
          },
          {
            title: "Vehicle Body Type",
            link: "/vehicle-body-type",
          },
          {
            title: "Vehicle Gear Type",
            link: "/vehicle-gear-type",
          },
          {
            title: "Vehicle Fuel Type",
            link: "/vehicle-fuel-type",
          },
        ],
      },
    ],
  },
  {
    title: "Ayarlar",
    icon: "settings",
    link: "/#",
    permission: [PermissionConsts.Settings],
    submenu: [
      {
        title: "Kullanıcılar",
        link: "/users",
      },
      {
        title: "Roller",
        link: "/users/roles",
      },
      {
        title: "Sözleşmeler",
        link: "/agreements",
      },
      {
        title: "Sözleşme Değişiklikleri",
        link: "/agreements/change-infos",
      },
      {
        title: "Parametreler",
        link: "/parameters",
      },
      {
        title: "Ödeme Ayarları",
        link: "/payment-settings",
      },
      {
        title: "Taksit Sayısı",
        link: "/payment-installment",
      },
      {
        title: "E-posta Ayarları",
        link: "/email-settings",
      },
      {
        title: "Euro Message Ayarları",
        link: "/euro-message-settings",
      },
      {
        title: "E-posta Tasarım Şablonları",
        link: "/email-templates",
      },
      {
        title: "Hatırlatıcı Ayarları",
        link: "/reminder-settings",
      },
      {
        title: "MTV Ayarları",
        link: "/mtv-settings",
      },
      {
        title: "Sosyal Medya Linkleri",
        link: "/social-media-links",
      },
      {
        title: "Bilgilendirilecek Alıcılar",
        link: "/informed-recipients",
      },
      {
        title: "Hatırlatıcı Kullanıcıları",
        link: "/system-reminder-users",
      },
      {
        title: "Telefon Numaraları",
        link: "/phone-numbers",
      },
      {
        title: "Uygulama Versiyonları",
        link: "/app-versions",
      },
      {
        title: "Rapor Dizayn",
        link: "/report-designer",
      },
    ],
  },
];
export interface MenuListProp {
  icon?: IconName;
  title: string;
  link?: string;
  className?: string;
  auth?: string[];
  submenu?: MenuListProp[];
  isNotAdmin?: boolean;
  permission?: string[];
}
