import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import MetaTags from "react-meta-tags";
import {
  Connector,
  Label,
  Legend,
  PieChart,
  Series,
  SmallValuesGrouping,
} from "devextreme-react/pie-chart";
import "../Customers/style.scss";
import { getCustomerDashboardChartList } from "src/store/reports/saga";
import { CustomerDashboardChartDto } from "src/store/reports/type";
import { isLoading } from "src/store/loader";
import Loader from "src/components/Loader";
import "./style.scss";
import { gridFilterSelector } from "src/store/app-system";
import CustomFilterBuilder from "./comp/filterBuilder";

const CustomerChartPage = () => {
  const gridFilterValue = useSelector(gridFilterSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const [chartData, setChartData] = useState<CustomerDashboardChartDto>();
  const columns = [
    {
      caption: t("Üye Grubu"),
      dataField: "CustomerGroupName",
    },
    {
      caption: t("Üye Durumu"),
      dataField: "StatusName",
    },

    {
      caption: t("Name"),
      dataField: "Name",
    },

    {
      caption: t("Surname"),
      dataField: "Surname",
    },
    {
      caption: t("PhoneNumber"),
      dataField: "PhoneNumber",
    },
    {
      caption: t("Email"),
      dataField: "Email",
    },
    {
      caption: t("Paket"),
      dataField: "LastPackage.PackagePackageName",
    },
    {
      caption: t("İlk Kayıt"),
      dataField: "CreateTime",
      dataType: "date",
    },
    {
      caption: t("İlk Satın Alma"),
      dataField: "FirstPackage.StartDate",
      dataType: "date",
    },
    {
      caption: t("Aktif Paket Başlangıç"),
      dataField: "LastPackage.StartDate",
      dataType: "date",
    },
    {
      caption: t("Aktif Paket Bitiş"),
      dataField: "LastPackage.EndDate",
      dataType: "date",
    },
    {
      caption: t("Yıllık"),
      dataField: "LastPackage.Yearly",
      dataType: "boolean",
    },
    {
      caption: t("Kupon"),
      dataField: "LastPackage.CouponCode",
    },
    {
      caption: t("Son Paket Fiyatı"),
      dataField: "LastPackage.Price",
    },

    {
      caption: t("Araç Ekleme"),
      dataField: "HasVehicle",
      dataType: "boolean",
    },
    {
      caption: t("Hediye"),
      dataField: "GiftPrivileges",
    },
    {
      caption: t("Toplam Üyelik Ay Sayısı"),
      dataField: "TotalMonths",
    },
    {
      caption: t("Ücretli Üyelik Ay Sayısı"),
      dataField: "TotalPaidMonths",
    },
    {
      caption: t("Ciro"),
      dataField: "TotalPaid",
    },
    {
      caption: t("Araç Marka"),
      dataField: "Vehicle.make",
    },
    {
      caption: t("Araç Model"),
      dataField: "Vehicle.model",
    },
    {
      caption: t("Araç Model Yıl"),
      dataField: "Vehicle.regdate",
    },
    {
      caption: t("Toplam İspark Kullanım Adedi"),
      dataField: "TotalIsparkCount",
    },
    {
      caption: t("Kupon Kullanım Sayısı"),
      dataField: "TotalUsedPrivileges",
    },
  ];

  useEffect(() => {
    dispatch(
      getCustomerDashboardChartList({
        payload: {
          onSuccess: (m, p) => {
            setChartData(p);
          },
          onError: () => {},
        },
        params: buildQuery(gridFilterValue),
      })
    );
  }, [dispatch, gridFilterValue]);
  const buildQuery = (filter: any) => {
    // Filtre ifadesini query stringe dönüştürün
    if (!filter) return "";
    // Burada basit bir örnek dönüştürme yapılmaktadır
    return `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
  };
  return (
    <React.Fragment>
      <div className={"page-content"}>
        <MetaTags>
          <title>{t("Customers")}</title>
        </MetaTags>
        <Container fluid>
          <React.Fragment>
            <CustomFilterBuilder columns={columns} />
            {loading && <Loader />}
            {chartData && (
              <Row className="mt-4">
                <Col md={3}>
                  <DevexpPieChart
                    data={chartData.Status}
                    title="Durumlara Göre"
                  />
                </Col>
                <Col md={3}>
                  <DevexpPieChart data={chartData.Isparks} title="İspark" />
                </Col>

                <Col md={3}>
                  <DevexpPieChart
                    data={chartData.Packages}
                    title="Paketlere Göre"
                  />
                </Col>
                <Col md={3}>
                  <DevexpPieChart
                    data={chartData.PackagePeriods}
                    title="Ay/Yıl"
                  />
                </Col>
                <Col md={3}>
                  <DevexpPieChart
                    data={chartData.Coupons}
                    title="Kuponlara Göre"
                  />
                </Col>
                <Col md={3}>
                  <DevexpPieChart
                    data={chartData.Groups}
                    title="Gruplara Göre"
                  />
                </Col>
                <Col md={3}>
                  <DevexpPieChart
                    data={chartData.CityGroups}
                    title="Şehirlere Göre"
                  />
                </Col>
              </Row>
            )}
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CustomerChartPage;

// const customizeLabel = (e: any) => `${e.argumentText}\n\n${e.valueText}`;

function DevexpPieChart({ data, title }: { data: any[]; title: string }) {
  const total = data.reduce((sum, item) => sum + item.Value, 0);
  function customizeLabel(pointInfo: any) {
    return `${pointInfo.argument}: ${pointInfo.percentText}`;
  }

  return (
    <Card>
      <CardHeader>{title}</CardHeader>
      <PieChart
        id="pie-chart"
        dataSource={data.map(item => ({
          ...item,
          Value: (item.Value / total) * 100,
          tag: item.Value,
        }))}
        resolveLabelOverlapping="shift"
        sizeGroup="piesGroup"
        innerRadius={0.65}
        type="doughnut"
      >
        <Series argumentField="Label" valueField="Value">
          <Label
            visible={true}
            format="fixedPoint"
            customizeText={customizeLabel}
            backgroundColor="none"
          >
            <Connector visible={true}></Connector>
          </Label>
          <SmallValuesGrouping threshold={3} mode="smallValueThreshold" />
        </Series>
        <Legend
          horizontalAlignment="center"
          verticalAlignment="bottom"
          columnCount={1}
        />
      </PieChart>
    </Card>
  );
}
