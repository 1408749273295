import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { MainListPage } from "src/components/main-list-page";

const PrivilegeLinkClickCountPage = () => {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const columns = [
    {
      caption: t("PrivilegeTitle"),
      dataField: "PrivilegeTitle",
    },
    {
      caption: t("Ad"),
      dataField: "CustomerName",
    },
    {
      caption: t("Soyad"),
      dataField: "CustomerSurname",
    },
    {
      caption: t("Telefon"),
      dataField: "CustomerPhoneNumber",
    },
    {
      caption: t("Link"),
      dataField: "Link",
    },
    {
      caption: t("Tarih"),
      dataField: "Date",
      dataType: "datetime",
    },
  ];
  const idValue = id === "all" ? "2e1ac5ca-382b-497c-8a04-b2d7310c0479" : id;
  return (
    <MainListPage
      title=""
      hideBreadcrumb={false}
      filter={id !== "all" ? ["PrivilegeId", "=", id] : undefined}
      columns={columns}
      url={"/api/privileges/link-click/" + idValue}
    />
  );
};
export default PrivilegeLinkClickCountPage;
