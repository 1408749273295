import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import { CheckRole } from "src/permissions/checkPermission";
import { userSelector } from "src/store/auth";
import { getPackageDetails, updatePackage } from "src/store/packages/saga";
import { PackageDetailDto } from "src/store/packages/type";
import * as Yup from "yup";

const EditPackage = ({
  data,
  setData,
}: {
  setData: (pack: PackageDetailDto) => void;
  data: PackageDetailDto;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const inputs = [
    {
      field: "PackageName",
      label: t("Package Name"),
    },
    {
      field: "Description",
      label: t("Description"),
      inputType: InputType.multilinetext,
    },

    {
      field: "MonthlyPrice",
      label: t("Monthly Price"),
      col: 6,
      inputType: InputType.number,
    },
    {
      field: "CampaignMonthlyPrice",
      label: t("Kampanyalı Aylık Fiyat"),
      col: 6,
      inputType: InputType.number,
    },
    {
      field: "YearlyPrice",
      label: t("Yearly Price"),
      col: 6,
      inputType: InputType.number,
    },
    {
      field: "CampaignYearlyPrice",
      label: t("Kampanyalı Yıllık Fiyat"),
      col: 6,
      inputType: InputType.number,
    },
    {
      field: "UpgradeYearlyPrice",
      label: t("Yıllık Paket Yükseltme Fiyatı"),
      col: 12,
      inputType: InputType.number,
    },
    {
      field: "CampaignStartDate",
      label: t("Start Date"),
      inputType: InputType.datetime,
    },
    {
      field: "CampaignEndDate",
      label: t("End Date"),
      inputType: InputType.datetime,
    },
    {
      field: "TaxRate",
      label: t("Vergi Oranı"),
      col: 6,
      inputType: InputType.number,
    },
    {
      field: "UsageCount",
      label: t("Kullanım Sayısı"),
      col: 6,
      inputType: InputType.number,
    },

    {
      field: "DontUseCoupon",
      label: t("Kupon Kullanılamaz"),
      uncheckedLabel: t("Pasive"),
      col: 6,
      inputType: InputType.checkbox,
    },

    {
      field: "IsMobile",
      label: t("IsMobile"),
      col: 3,
      inputType: InputType.checkbox,
    },
    {
      field: "System",
      label: t("System"),
      col: 3,
      inputType: InputType.checkbox,
    },
    {
      field: "UseDate",
      label: t("Kampanya için Tarihi Baz Al"),
      col: 6,
      inputType: InputType.checkbox,
    },
    {
      field: "Active",
      label: t("Active"),
      checkedLabel: t("Active"),
      uncheckedLabel: t("Active"),
      inputType: InputType.toogle,
    },
    {
      field: "ImageFile",
      label: t("ImageFile Image"),
      inputType: InputType.fileUpload,
      fileUpload: {
        linkField: "CurrentImageFile",
        accept: ["image/jpeg", "image/png"],
        multiple: false,
        title: t("Please Upload Image"),
      },
    },
  ];
  // const getIcon = (bool: boolean) => {
  //   return bool ? (
  //     <i className="mdi mdi-check-circle text-success font-size-14 ms-1" />
  //   ) : (
  //     <i className="mdi mdi-close-circle text-danger font-size-14 ms-1" />
  //   );
  // };
  const user = useSelector(userSelector);
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          Id: data.Id,
          PackageName: data.PackageName,
          MonthlyPrice: data.MonthlyPrice,
          YearlyPrice: data.YearlyPrice,
          Description: data.Description,
          IsMobile: data.IsMobile,
          System: data.System,
          Active: data.Active,
          CampaignMonthlyPrice: data.CampaignMonthlyPrice ?? "",
          CampaignYearlyPrice: data.CampaignYearlyPrice ?? "",
          CampaignEndDate: data.CampaignEndDate ?? "",
          CampaignStartDate: data.CampaignStartDate ?? "",
          // CampaignStartDate: data.CampaignStartDate ?? "",
          TaxRate: data.TaxRate ?? "",
          UseDate: data.UseDate ?? false,
          DontUseCoupon: data.DontUseCoupon ?? false,
          UsageCount: data.UsageCount ?? "",
          CurrentImageFile: data.Image ?? "",
          ImageFile: "",
          UpgradeYearlyPrice: data.UpgradeYearlyPrice ?? 0,
        }}
        onSubmit={values => {
          dispatch(
            updatePackage({
              payload: {
                onSuccess: (message, payload) => {
                  toast.success("Paketiniz Güncellendi", {
                    theme: "colored",
                  });
                  dispatch(
                    getPackageDetails({
                      payload: {
                        onSuccess: (msg, payload) => {
                          setData(payload);
                          return setDisabled(true);
                        },
                        onError: () => {},
                      },
                      id: data.Id,
                    })
                  );
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PackageName: Yup.mixed().required(t("Required")),
          MonthlyPrice: Yup.number().required(t("Required")),
          YearlyPrice: Yup.number().required(t("Required")),
          Description: Yup.string().required(t("Required")),
          TaxRate: Yup.number().required(t("Required")),
          CampaignStartDate: Yup.string().when(
            ["CampaignMonthlyPrice", "CampaignYearlyPrice"],
            {
              is: (m: any, y: any) =>
                (y !== "" && y > 0) || (m !== "" && m > 0),
              then: Yup.string().required(t("Required")),
            }
          ),
          CampaignEndDate: Yup.string().when(
            ["CampaignMonthlyPrice", "CampaignYearlyPrice"],
            {
              is: (m: any, y: any) =>
                (y !== "" && y > 0) || (m !== "" && m > 0),
              then: Yup.string().required(t("Required")),
            }
          ),
        })}
      >
        {({
          touched,
          errors,
          values,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => (
          <>
            <Row style={{ width: "100%" }}>
              {disabled ? (
                <>
                  {inputs.map((key, i) => (
                    <GetInput
                      inputprop={key}
                      key={i}
                      disabledvalue={disabled}
                    />
                  ))}
                  {CheckRole(user) && (
                    <div className={"d-flex justify-content-end"}>
                      <Button
                        color="light"
                        type="submit"
                        style={{ fontSize: 12 }}
                        onClick={() => setDisabled(!disabled)}
                      >
                        {t("Edit")}
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                // <div className="active">

                // </div>
                <>
                  {inputs.map((key, i) => (
                    <GetInput
                      inputprop={key}
                      key={i}
                      disabledvalue={disabled}
                    />
                  ))}
                </>
              )}
              {!disabled && CheckRole(user) && (
                <div className={"d-flex mt-2  justify-content-end gap-2"}>
                  <Button
                    color="danger"
                    type="submit"
                    style={{ fontSize: 12 }}
                    onClick={() => setDisabled(!disabled)}
                  >
                    {t("Close")}
                  </Button>
                  <Button
                    color="success"
                    style={{ fontSize: 12 }}
                    type="submit"
                    onClick={() => handleSubmit()}
                  >
                    {t("Save")}
                  </Button>
                </div>
              )}
            </Row>
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default EditPackage;
